<template>
  <div class="container app-content pt-4">
    <!-- Page Header -->
    <PageHeader title="Pledges" />

    <Error v-if="error" :error="error" />
    <Loading v-else-if="isLoading" />
    <template v-else>
      <PledgesNavigation @searchQuery="searchQuery" />
      <PaginationResults
        :total="meta.pagination.total"
        :current-page="pageNum"
        :total-pages="meta.pagination.total_pages"
        :per-page="perPage"
        @searchQuery="searchQuery"
      />
      <div class="row">
        <div class="col">
          <PledgesList :pledges="pledges" :striped="true" :hover="true" />
        </div>
      </div>
      <Pagination
        :total="meta.pagination.total"
        :current-page="pageNum"
        :total-pages="meta.pagination.total_pages"
        :per-page="perPage"
        @pageFirst="pageFirst"
        @pageLast="pageLast"
        @pageNext="pageNext"
        @pagePrevious="pagePrevious"
        @toPage="toPage"
        @setPerPage="setPerPage"
      />
    </template>

    <Modal id="modal-pledge-create">
      <template v-slot:title>Create a Pledge</template>

      <template v-slot:body>
        <PledgeForm modal="#modal-pledge-create" @complete="fetchData" />
      </template>
    </Modal>
  </div>
</template>

<script>
import Error from 'components/Error';
import hasTimeDate from 'mixins/hasTimeDate';
import Loading from 'components/Loading';
import Modal from 'components/Modal';
import PageHeader from 'components/PageHeader';
import Pagination from 'components/Pagination';
import PaginationResults from 'components/PaginationResults';
import paginationFunctions from 'mixins/paginationFunctions';
import PledgeForm from 'components/forms/PledgeForm';
import PledgesList from 'components/pledges/PledgesList';
import PledgesNavigation from 'components/pledges/PledgesNavigation';

export default {
  name: 'PledgesIndex',
  mixins: [hasTimeDate, paginationFunctions],
  components: {
    Error,
    Loading,
    Modal,
    PageHeader,
    Pagination,
    PaginationResults,
    PledgeForm,
    PledgesList,
    PledgesNavigation,
  },
  data() {
    return {
      error: null,
      isLoading: false,
      pageNum: Number(this.$route.query.page) || 1,
      perPage: Number(this.$route.query.per_page) || 12,
    };
  },
  computed: {
    pledges() {
      return this.$store.getters['pledges/getAll'];
    },
    hasPledges() {
      return this.pledges.length > 0;
    },
    meta() {
      return this.$store.getters['pledges/getAllMeta'];
    },
    q() {
      return this.$store.getters['search/getKeywords'];
    },
    dates() {
      return this.$store.getters['search/getRange'];
    },
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
  unmounted() {
    // console.log('PledgesIndex unmounted()');
    this.$store.commit('search/clearSearch');
  },
  methods: {
    fetchData(params) {
      this.error = null;
      this.isLoading = true;

      const defaultParams = {
        page: this.pageNum,
        per_page: this.perPage,
        with: 'statistics',
      };

      const payload = {
        params: Object.assign(defaultParams, params || {}),
      };

      if (this.q) {
        payload.params.search = this.q;

        const numericFields = ['campaign_id', 'promotion_id', 'amount', 'zip'];
        const textFields = ['display_as', 'first', 'last', 'city', 'street', 'state', 'email'];

        if (isNaN(this.q)) {
          payload.params.searchFields = textFields.join(';');
        } else {
          payload.params.searchFields = numericFields.join(';');
        }
      }

      // Check for limits imposed by a campaign, promotion, or manually set date range
      const dateLimits = this.getDateLimits();
      if (dateLimits) {
        payload.params.timeslice = dateLimits;
      }

      // console.log('PledgesIndex methods fetchData() :payload', payload);

      this.$store
        .dispatch('pledges/getAll', payload)
        .then(() => {
          // console.log('PledgesIndex methods fetchData() getAll then');
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        });
    },
    getDateLimits() {
      // Default to no limits;
      let limits = false;
      let start = false;
      let end = false;

      if (this.dates) {
        // Start with the most specific, manually set dates in the search bar. This takes precedence over all others
        ({ start, end } = this.dates);
      }

      if (start && end) {
        // If we have values for the date range, set them and pass back to the caller
        limits = `created_at;${this.formatISODate(start)}|${this.formatISODate(end)}`;
      }

      return limits;
    },
    searchQuery() {
      // console.log('PledgesIndex methods searchQuery()');
      this.pageNum = 1;

      this.fetchData();
    },
  },
};
</script>

<style scoped lang="scss"></style>
